import React, { useState } from "react";
import { Col, Card } from "react-bootstrap";
import { useNavigate } from "react-router";
import { activateMenu } from "../../redux/actions";
import { useAppDispatch } from "../../redux/store";
import Loading from "../../pages/loading/loading";

type Props = {
  name: string;
  path: string;
  icon: string;
};
export const DashboardCard: React.FC<Props> = ({ name, path, icon }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleCardClick = (path: string) => {
    setIsLoading(true);
    console.log("loading");
    dispatch(activateMenu(path));
    setTimeout(() => {
      setIsLoading(false); // Reset isLoading after timeout
      console.log("loading stopped");
      navigate(path, { replace: true });
    }, 5000);
  };
  // setIsLoading(false);
  // console.log("Loading stopped");
  // console.log("waiting for navigate");
  // navigate(path, { replace: true });
  // console.log("nav complete");

  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleHover = () => {
    setIsHovered(!isHovered);
  };
  return (
    <Col
      md={4}
      xl={3}
      xs={6}
      className="cursor-pointer mb-4"
      onClick={() => {
        handleCardClick(path);
      }}
    >
      <Card className="border-left-primary shadow h-100 py-2">
        <Card.Body
          className={isHovered ? "hovered" : "card"}
          onMouseEnter={handleHover}
          onMouseLeave={handleHover}
        >
          <div className="row no-gutters align-items-center">
            <div className="col mr-2">
              <div className="texts text-xs font-weight-bold text-uppercase mb-1">
                {name}
              </div>
              <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
            </div>
            <div className="col-auto">
              <i className={`fas ${icon} fa-2x text-gray-300 `}></i>
            </div>
          </div>
        </Card.Body>
        {isLoading && <Loading />}
      </Card>
    </Col>
  );
};

{
  /* <DashboardCard name="Dashboard" path="/dashboard" icon="fas fa-tachometer-alt" />
<DashboardCard name="Settings" path="/settings" icon="fas fa-cog" />
<DashboardCard name="Messages" path="/messages" icon="fas fa-envelope" /> */
}
