import { APIService } from "../services";

export const getLikes = async (
  pageNo: number,
  pageSize: number,
  search: string
) => {
  let res;
  if (search.length === 0) {
    res = await getAllLikes(pageNo, pageSize);
  } else {
    try {
      res = await searchLikes(search, pageNo, pageSize);
    } catch (err) {
      return { records: [], total: 0 };
    }
  }
  if (res && res.data && res.data.data) {
    return { records: res.data.data.data, total: res.data.data.total };
  } else {
    return { records: [], total: 0 };
  }
};

export const addLikes = (data: any) => {
  return APIService.api().post(`/likes`, data);
};
export const updateLikes = (like_id: any, data: any) => {
  return APIService.api().put(`/likes/${like_id}`, data);
};
export const getAllLikes = (pageNo: number, pageSize: number) => {
  return APIService.api().get(`/likes?pageNo=${pageNo}&paginator=${pageSize}`);
};
export const getOneLikes = (like_id: any) => {
  return APIService.api().get(`/likes/${like_id}`);
};
export const searchLikes = (
  searchKey: string,
  pageNo: number,
  pageSize: number
) => {
  return APIService.api().get(
    `/likes/search/${searchKey}?pageNo=${pageNo}&paginator=${pageSize}`
  );
};
export const deleteLikes = (like_id: any) => {
  return APIService.api().delete(`/likes/${like_id}`);
};
