import { APIService } from "../services";

export const getArticles = async (
  pageNo: number,
  pageSize: number,
  search: string
) => {
  let res;
  if (search.length === 0) {
    res = await getAllArticles(pageNo, pageSize);
  } else {
    try {
      res = await searchArticles(search, pageNo, pageSize);
    } catch (err) {
      return { records: [], total: 0 };
    }
  }
  if (res && res.data && res.data.data) {
    return { records: res.data.data.data, total: res.data.data.total };
  } else {
    return { records: [], total: 0 };
  }
};

export const addArticles = (data: any) => {
  return APIService.api().post(`/articles`, data);
};
export const updateArticles = (article_id: any, data: any) => {
  return APIService.api().put(`/articles/${article_id}`, data);
};
export const getAllArticles = (pageNo: number, pageSize: number) => {
  return APIService.api().get(
    `/articles?pageNo=${pageNo}&paginator=${pageSize}`
  );
};
export const getOneArticles = (article_id: any) => {
  return APIService.api().get(`/articles/${article_id}`);
};
export const searchArticles = (
  searchKey: string,
  pageNo: number,
  pageSize: number
) => {
  return APIService.api().get(
    `/articles/search/${searchKey}?pageNo=${pageNo}&paginator=${pageSize}`
  );
};
export const deleteArticles = (article_id: any) => {
  return APIService.api().delete(`/articles/${article_id}`);
};
