import { useFormik } from "formik";
import { Button, Card, Form } from "react-bootstrap";
import React, { useState } from "react";

import { useAppDispatch } from "../../redux/store";
import { setUsersMessage } from "../../redux/actions";
import { addUsers, updateUsers } from "../../services/usersService";
import { Constant } from "../../template/Constant";
import * as yup from "yup";
import Loading from "../../pages/loading/loading";

type Props = {
  row?: any;
  hideShowForm: (actionName: string) => void;
  getData: (page: number, pageSize: number, searchKey: string) => void;
  action?: string;
};
export const UsersForm: React.FC<Props> = ({
  row,
  hideShowForm,
  getData,
  action,
}) => {
  const dispatch = useAppDispatch();
  const iValue = {
    user_id: "",
    username: "",
    email: "",
    password_hash: "",
    registration_date: "",
    is_admin: "",
  };
  const initialValue = action === "edit" ? row : iValue;
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: async (values) => {
      if (action === "edit") {
        setIsLoading(true);
        const response = await updateUsers(values.user_id, values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(setUsersMessage("Updated Successfully"));
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(setUsersMessage("Some error occured!"));
        }
      } else if (action === "add") {
        setIsLoading(true);
        const response = await addUsers(values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(setUsersMessage("Added Successfully"));
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(setUsersMessage("Some error occured!"));
        }
      }
      setIsLoading(false);
    },
    validationSchema: yup.object({
      username: yup.string().required("Username is required"),
      email: yup.string().required("Email is required"),
      password_hash: yup.string().required("Password Hash is required"),
      is_admin: yup.boolean().required("Admin is required"),
    }),
  });
  return (
    <Card className="shadow mb-4">
      {isLoading && <Loading />}

      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary text-capitalize">
          {action} Users
          <Button
            className="btn-icon-split float-right"
            onClick={() => hideShowForm("")}
          >
            <span className="icon text-white-50">
              <i className="fas fa-list"></i>
            </span>
            <span className="text">View Users</span>
          </Button>
        </h6>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group>
            <label className="form -control-label">Username</label>
            <Form.Control
              type="text"
              name="username"
              className="form-control"
              value={formik.values.username}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.touched.username && !!formik.errors.username}
              isValid={!!formik.touched.username && !formik.errors.username}
            ></Form.Control>
            {formik.errors.username && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.username}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Email</label>
            <Form.Control
              type="text"
              name="email"
              className="form-control"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.touched.email && !!formik.errors.email}
              isValid={!!formik.touched.email && !formik.errors.email}
            ></Form.Control>
            {formik.errors.email && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.email}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Password Hash</label>
            <Form.Control
              type="text"
              name="password_hash"
              className="form-control"
              value={formik.values.password_hash}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.password_hash && !!formik.errors.password_hash
              }
              isValid={
                !!formik.touched.password_hash && !formik.errors.password_hash
              }
            ></Form.Control>
            {formik.errors.password_hash && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.password_hash}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Admin</label>
            <Form.Control
              as="select"
              name="is_admin"
              className="form-control"
              value={formik.values.is_admin}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.touched.is_admin && !!formik.errors.is_admin}
              isValid={!!formik.touched.is_admin && !formik.errors.is_admin}
            >
              <option value="">Select...</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Form.Control>
            {formik.errors.is_admin && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.is_admin}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group>
            <Button
              type="submit"
              className="float-right mt-2"
              variant="primary"
            >
              {isLoading && <Loading />}
              Save
            </Button>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
};
