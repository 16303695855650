import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./login";
import Register from "./register";
import AuthenticatedRoute from "../components/auth";
import { Dashboard } from "../components/dashboard";
// import { FileUpload } from "../components/upload";
import { NotFound } from "./404";
import {
  Adsense,
  Articles,
  Categories,
  Comments,
  Likes,
  Settings,
  Status,
  Users,
} from "../components";
const Pages: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route path="/register" element={<Register />}></Route>
        <Route
          path="/dashboard"
          element={<AuthenticatedRoute element={<Dashboard />} />}
        ></Route>
        {/* <Route
          path="/upload"
          element={<AuthenticatedRoute element={<FileUpload />} />}
        ></Route> */}
        <Route
          path="/adsense"
          element={<AuthenticatedRoute element={<Adsense />} />}
        />
        <Route
          path="/articles"
          element={<AuthenticatedRoute element={<Articles />} />}
        />
        <Route
          path="/categories"
          element={<AuthenticatedRoute element={<Categories />} />}
        />
        <Route
          path="/comments"
          element={<AuthenticatedRoute element={<Comments />} />}
        />
        <Route
          path="/likes"
          element={<AuthenticatedRoute element={<Likes />} />}
        />
        <Route
          path="/settings"
          element={<AuthenticatedRoute element={<Settings />} />}
        />
        <Route
          path="/status"
          element={<AuthenticatedRoute element={<Status />} />}
        />
        <Route
          path="/users"
          element={<AuthenticatedRoute element={<Users />} />}
        />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Pages;
