import React, { FC } from "react";
import { Navigate } from "react-router";

const AuthenticatedRoute: FC<{ element: any }> = ({ element }) => {
  const token = localStorage.getItem("token");
  const expire = localStorage.getItem("expire");

  if (token && expire) {
    const now = Date.now();
    const newTime = now + parseInt(expire);
    const expireAt = new Date(newTime);
    if (token && expire && expireAt >= new Date()) {
      return element;
    } else {
      return <Navigate to="/" replace />;
    }
  } else {
    return <Navigate to="/" replace />;
  }
};

export default AuthenticatedRoute;
