import { APIService } from "../services";

export const getStatus = async (
  pageNo: number,
  pageSize: number,
  search: string
) => {
  let res;
  if (search.length === 0) {
    res = await getAllStatus(pageNo, pageSize);
  } else {
    try {
      res = await searchStatus(search, pageNo, pageSize);
    } catch (err) {
      return { records: [], total: 0 };
    }
  }
  if (res && res.data && res.data.data) {
    return { records: res.data.data.data, total: res.data.data.total };
  } else {
    return { records: [], total: 0 };
  }
};

export const addStatus = (data: any) => {
  return APIService.api().post(`/status`, data);
};
export const updateStatus = (status_id: any, data: any) => {
  return APIService.api().put(`/status/${status_id}`, data);
};
export const getAllStatus = (pageNo: number, pageSize: number) => {
  return APIService.api().get(`/status?pageNo=${pageNo}&paginator=${pageSize}`);
};
export const getOneStatus = (status_id: any) => {
  return APIService.api().get(`/status/${status_id}`);
};
export const searchStatus = (
  searchKey: string,
  pageNo: number,
  pageSize: number
) => {
  return APIService.api().get(
    `/status/search/${searchKey}?pageNo=${pageNo}&paginator=${pageSize}`
  );
};
export const deleteStatus = (status_id: any) => {
  return APIService.api().delete(`/status/${status_id}`);
};
