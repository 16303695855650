import React, { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { setCommentsList, setCommentsMessage } from "../../redux/actions";
import { RootState } from "../../redux/reducers";
import { useAppDispatch } from "../../redux/store";
import { getComments } from "../../services/commentsService";
import Layout from "../../template";
import { Constant } from "../../template/Constant";
import { CommentsForm } from "./form";
import { CommentsTable } from "./table";

export const Comments: React.FC = () => {
  const dispatch = useAppDispatch();
  const rData = useSelector((state: RootState) => state.comments);
  const [row, setRow] = useState(undefined);
  const [action, setAction] = useState("");
  const getData = (page: number, pageSize: number, searchKey: string) => {
    getComments(page, pageSize, searchKey).then((response) => {
      if (response && response.records) {
        dispatch(
          setCommentsList({
            pageNo: page,
            pageSize: pageSize,
            list: response.records,
            totalCount: response.total,
            searchKey: searchKey,
          })
        );
      } else {
        dispatch(setCommentsMessage("No Record Found"));
      }
    });
  };

  const handleRowEdit = (rowData: any) => {
    setRow(rowData);
    dispatch(setCommentsMessage(""));
    setAction("edit");
  };
  return (
    <Layout>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Comments</h1>
        </div>
        <div className="d-flex flex-column min-vh-100">
          {rData.message ? (
            <Alert
              variant={Constant.defaultAlertVarient}
              className="alert-dismissible fade"
            >
              {rData.message}
              <Button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
                onClick={() => dispatch(setCommentsMessage(""))}
              >
                <span aria-hidden="true">&times;</span>
              </Button>
            </Alert>
          ) : null}
          {action ? (
            <CommentsForm
              hideShowForm={setAction}
              action={action}
              row={row}
              getData={getData}
            />
          ) : (
            <CommentsTable
              handleRowEdit={handleRowEdit}
              hideShowForm={setAction}
              getData={getData}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};
