import { useFormik } from "formik";
import { Alert, Button, Card, Col, Form } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";

import { resetCategoriesToInit, setCategoriesList } from "../../redux/actions";
import { resetUsersToInit, setUsersList } from "../../redux/actions";

import { getCategories } from "../../services/categoriesService";
import { getUsers } from "../../services/usersService";

import { useAppDispatch } from "../../redux/store";
import { setArticlesMessage } from "../../redux/actions";
import { addArticles, updateArticles } from "../../services/articlesService";
import { Constant } from "../../template/Constant";
import * as yup from "yup";
import { uploadImageService } from "../../services/fileUploadService";
import Loading from "../../pages/loading/loading";

type Props = {
  row?: any;
  hideShowForm: (actionName: string) => void;
  getData: (page: number, pageSize: number, searchKey: string) => void;
  action?: string;
};
export const ArticlesForm: React.FC<Props> = ({
  row,
  hideShowForm,
  getData,
  action,
}) => {
  const [message, setMessage] = useState("");
  const [articleIcon, setArticleIcon] = useState(row?.article_icon || ""); // State for article icon image URL
  const [articleImage, setArticleImage] = useState(row?.image_url || ""); // State for article icon image URL
  const [isLoading, setIsLoading] = useState(false);

  const formikImage = useFormik({
    initialValues: { image: null },
    onSubmit: async (values) => {
      if (values.image) {
        var formData = new FormData();

        // Check if image is truthy before appending
        formData.append("image", values.image); // Use values.image directly without [0]

        try {
          const res = await uploadImageService(formData);
          if (res && res.data) {
            setArticleIcon(res.data.data); // Set article icon image URL
            setMessage("Image Successfully Uploaded : " + res.data.data);
            formik.setFieldValue("article_icon", res.data.data);
            console.log(res.data.data);

            formikImage.resetForm();
          } else {
            setMessage("Image not sent");
          }
        } catch (error) {
          setMessage("Failed to upload image");
          console.log("Upload error:", error);
        }
      }
    },
    validationSchema: yup.object({
      image: yup.mixed().required("Select at least 1 image"),
    }),
  });

  const [message2, setMessage2] = useState("");
  const formikImage2 = useFormik({
    initialValues: { image2: null },
    onSubmit: async (values) => {
      if (values.image2) {
        var formData = new FormData();
        formData.append("image", values.image2);

        try {
          const res = await uploadImageService(formData);
          if (res && res.data) {
            setArticleImage(res.data.data);
            setMessage2("Image Successfully Uploaded : " + res.data.data);
            formik.setFieldValue("image_url", res.data.data);
            formikImage2.resetForm();
          } else {
            setMessage2("Image not sent");
          }
        } catch (error) {
          setMessage2("Failed to upload image ");
          console.log("Upload error :", error);
        }
      }
    },
    validationSchema: yup.object({
      image2: yup.mixed().required("Select at least 1 image"),
    }),
  });

  const dispatch = useAppDispatch();
  const iValue = {
    author: "",
    article_id: "",
    article_title: "",
    article_link: "",
    article_icon: "",
    image_url: "",
    category_id: "",
    user_id: "",
    creation_date: "",
  };

  const initialValue =
    action === "edit"
      ? { ...row, article_icon: articleIcon, image_url: articleImage }
      : iValue;

  const categoriesData = useSelector((state: RootState) => state.categories);
  const usersData = useSelector((state: RootState) => state.users);

  useEffect(() => {
    if (
      categoriesData &&
      categoriesData.list &&
      categoriesData.list.length === 0
    ) {
      dispatch(resetCategoriesToInit());
      getCategories(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setCategoriesList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total,
              searchKey: "",
            })
          );
        } else {
          dispatch(setArticlesMessage("No Record Found For Categories"));
        }
      });
    }
    if (usersData && usersData.list && usersData.list.length === 0) {
      dispatch(resetUsersToInit());
      getUsers(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setUsersList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total,
              searchKey: "",
            })
          );
        } else {
          dispatch(setArticlesMessage("No Record Found For Users"));
        }
      });
    }
  });
  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: async (values) => {
      if (action === "edit") {
        setIsLoading(true);
        const response = await updateArticles(values.article_id, values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(setArticlesMessage("Updated Successfully"));
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(setArticlesMessage("Some error occured!"));
        }
      } else if (action === "add") {
        setIsLoading(true);

        const response = await addArticles(values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(setArticlesMessage("Added Successfully"));
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(setArticlesMessage("Some error occured!"));
        }
      }
      setIsLoading(false);
    },
    validationSchema: yup.object({
      article_title: yup.string().required("Article Title is Required"),
      author: yup.string().required("Author is Required"),
      article_link: yup.string().required("Article Link is Required"),
      article_icon: yup.string().required("Article Icon is Required"),
      image_url: yup.string().required("Image Url is Required"),
      category_id: yup.number().required("category ID is Required"),
      user_id: yup.number().required("user ID is Required"),
    }),
  });

  const handleUpload = () => {
    // setIsLoading(true);
    console.log("load");
    setTimeout(() => {
      setIsLoading(false); // Reset isLoading after timeout
      console.log("loading stopped");
    }, 5000);

    if (formikImage.values.image) {
      setIsLoading(true);
      console.log("load");
      formikImage.handleSubmit(); // Trigger image upload
    } else {
      setMessage("Please select an image before uploading.");
      setIsLoading(false);
    }
  };
  const handleUpload2 = () => {
    // setIsLoading(true);
    console.log("load");
    setTimeout(() => {
      setIsLoading(false); // Reset isLoading after timeout
      console.log("loading stopped");
    }, 5000);

    if (formikImage2.values.image2) {
      setIsLoading(true);
      console.log("load");
      formikImage2.handleSubmit(); // Trigger image upload
    } else {
      setMessage("Please select an image before uploading.");
      setIsLoading(false);
    }
  };

  return (
    <Card className="shadow mb-4">
      {isLoading && <Loading />}
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary text-capitalize">
          {action} Articles
          <Button
            className="btn-icon-split float-right"
            onClick={() => hideShowForm("")}
          >
            <span className="icon text-white-50">
              <i className="fas fa-list"></i>
            </span>
            <span className="text">View Articles</span>
          </Button>
        </h6>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group>
            <label className="form -control-label">Article Title</label>
            <Form.Control
              type="text"
              name="article_title"
              className="form-control"
              value={formik.values.article_title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.article_title && !!formik.errors.article_title
              }
              isValid={
                !!formik.touched.article_title && !formik.errors.article_title
              }
            ></Form.Control>
            {formik.errors.article_title && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.article_title}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Author</label>
            <Form.Control
              type="text"
              name="author"
              className="form-control"
              value={formik.values.author}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.touched.author && !!formik.errors.author}
              isValid={!!formik.touched.author && !formik.errors.author}
            ></Form.Control>
            {formik.errors.author && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.author}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Article Link</label>
            <Form.Control
              type="text"
              name="article_link"
              className="form-control"
              value={formik.values.article_link}
              onChange={(event) => {
                const { name, value } = event.target;
                formik.handleChange(event);
                formik.setFieldValue(name, value.toLowerCase());
              }}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.article_link && !!formik.errors.article_link
              }
              isValid={
                !!formik.touched.article_link && !formik.errors.article_link
              }
            ></Form.Control>
            {formik.errors.article_link && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.article_link}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group>
            <label className="form -control-label">Category</label>
            <Form.Control
              as="select"
              name="category_id"
              className="form-control"
              value={formik.values.category_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.category_id && !!formik.errors.category_id
              }
              isValid={
                !!formik.touched.category_id && !formik.errors.category_id
              }
            >
              <option value={0}>Select Categories </option>
              {categoriesData &&
                categoriesData.list &&
                categoriesData.list.map((item, i) => {
                  return (
                    <option value={item.category_id} key={`categories-${i}`}>
                      {item.category_name}
                    </option>
                  );
                })}
            </Form.Control>
            {formik.errors.category_id && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.category_id}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">User</label>
            <Form.Control
              as="select"
              name="user_id"
              className="form-control"
              value={formik.values.user_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.touched.user_id && !!formik.errors.user_id}
              isValid={!!formik.touched.user_id && !formik.errors.user_id}
            >
              <option value={0}>Select User </option>
              {usersData &&
                usersData.list &&
                usersData.list.map((item, i) => {
                  return (
                    <option value={item.user_id} key={`users-${i}`}>
                      {item.username}
                    </option>
                  );
                })}
            </Form.Control>
            {formik.errors.user_id && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.user_id}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Upload Icon (png, jpg, or jpeg files)</Form.Label>
            <div className="d-flex">
              <Form.Control
                type="file"
                name="image"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const imgFile = event.currentTarget.files?.[0]; // Access files array properly
                  formikImage.setFieldValue("image", imgFile); // Set the file directly
                }}
                onBlur={formikImage.handleBlur}
                isInvalid={
                  !!formikImage.errors.image && !!formikImage.touched.image
                }
                isValid={
                  !formikImage.errors.image && !!formikImage.touched.image
                }
              />
              {formikImage.errors.image && (
                <Form.Control.Feedback type="invalid">
                  {formikImage.errors.image}
                </Form.Control.Feedback>
              )}
              <Button variant="primary" className="ml-2" onClick={handleUpload}>
                Upload
              </Button>
              {isLoading && <Loading />}
            </div>
          </Form.Group>
          {message ? (
            <Col md={12}>
              <Alert variant="info">{message}</Alert>
            </Col>
          ) : null}
          <Form.Group>
            <label className="form -control-label">Article Icon Url</label>
            <Form.Control
              type="text"
              name="article_icon_url"
              className="form-control"
              value={formik.values.article_icon}
              onChange={formik.handleChange}
              readOnly={action === "edit"}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.article_icon && !!formik.errors.article_icon
              }
              isValid={
                !!formik.touched.article_icon && !formik.errors.article_icon
              }
            ></Form.Control>
            {formik.errors.article_icon && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.article_icon}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group controlId="formFile2" className="mb-3">
            <Form.Label>Upload Image (png, jpg, or jpeg files)</Form.Label>
            <div className="d-flex">
              <Form.Control
                type="file"
                name="image2"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const imgFile2 = event.currentTarget.files?.[0];
                  formikImage2.setFieldValue("image2", imgFile2);
                }}
                onBlur={formikImage2.handleBlur}
                isInvalid={
                  !!formikImage2.errors.image2 && !!formikImage2.touched.image2
                }
                isValid={
                  !formikImage2.errors.image2 && !!formikImage2.touched.image2
                }
              />
              {formikImage2.errors.image2 && (
                <Form.Control.Feedback type="invalid">
                  {formikImage2.errors.image2}
                </Form.Control.Feedback>
              )}
              <Button
                variant="primary"
                className="ml-2"
                onClick={handleUpload2}
              >
                Upload
              </Button>
              {isLoading && <Loading />}
            </div>
          </Form.Group>
          {message2 ? (
            <Col md={12}>
              <Alert variant="info">{message2}</Alert>
            </Col>
          ) : null}
          <Form.Group>
            <label className="form -control-label">Article Image Url</label>
            <Form.Control
              type="text"
              name="article_image_url"
              className="form-control"
              value={formik.values.image_url}
              onChange={formik.handleChange}
              readOnly={action === "edit"}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.image_url && !!formik.errors.image_url
              }
              isValid={!!formik.touched.image_url && !formik.errors.image_url}
            ></Form.Control>
            {formik.errors.image_url && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.image_url}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group>
            <Button
              type="submit"
              className="float-right mt-2"
              variant="primary"
            >
              {isLoading && <Loading />}
              Save
            </Button>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
};
