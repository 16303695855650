import { APIService } from "../services";

export const getSettings = async (
  pageNo: number,
  pageSize: number,
  search: string
) => {
  let res;
  if (search.length === 0) {
    res = await getAllSettings(pageNo, pageSize);
  } else {
    try {
      res = await searchSettings(search, pageNo, pageSize);
    } catch (err) {
      return { records: [], total: 0 };
    }
  }
  if (res && res.data && res.data.data) {
    return { records: res.data.data.data, total: res.data.data.total };
  } else {
    return { records: [], total: 0 };
  }
};

export const addSettings = (data: any) => {
  return APIService.api().post(`/settings`, data);
};
export const updateSettings = (setting_id: any, data: any) => {
  return APIService.api().put(`/settings/${setting_id}`, data);
};
export const getAllSettings = (pageNo: number, pageSize: number) => {
  return APIService.api().get(
    `/settings?pageNo=${pageNo}&paginator=${pageSize}`
  );
};
export const getOneSettings = (setting_id: any) => {
  return APIService.api().get(`/settings/${setting_id}`);
};
export const searchSettings = (
  searchKey: string,
  pageNo: number,
  pageSize: number
) => {
  return APIService.api().get(
    `/settings/search/${searchKey}?pageNo=${pageNo}&paginator=${pageSize}`
  );
};
export const deleteSettings = (setting_id: any) => {
  return APIService.api().delete(`/settings/${setting_id}`);
};
