import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IArticles } from "../../../interfaces/articles";

interface IArticlesData {
  list?: Array<IArticles>;
  pageNo: number;
  pageSize: number;
  searchKey?: string;
  totalCount?: number;
  message?: string;
}

const initialState: IArticlesData = {
  pageNo: 1,
  pageSize: 20,
  searchKey: "",
  list: [],
  totalCount: 0,
  message: "",
};

const articlesSlice = createSlice({
  name: "articles",
  initialState,
  reducers: {
    setArticlesList: (state, _action: PayloadAction<IArticlesData>) => {
      state.list = _action.payload.list;
      state.pageNo = _action.payload.pageNo;
      state.pageSize = _action.payload.pageSize;
      state.totalCount = _action.payload.totalCount;
    },
    resetArticlesToInit: (state) => {
      state = initialState;
    },
    setArticlesMessage: (state, _action: PayloadAction<string>) => {
      state.message = _action.payload;
    },
  },
});

export const { setArticlesList, resetArticlesToInit, setArticlesMessage } =
  articlesSlice.actions;

export default articlesSlice.reducer;
