import { useFormik } from "formik";
import { Alert, Button, Card, Col, Form } from "react-bootstrap";
import React, { useState } from "react";

import { useAppDispatch } from "../../redux/store";
import { setCategoriesMessage } from "../../redux/actions";
import {
  addCategories,
  updateCategories,
} from "../../services/categoriesService";
import { Constant } from "../../template/Constant";
import * as yup from "yup";
import { uploadImageService } from "../../services/fileUploadService";
import Loading from "../../pages/loading/loading";

type Props = {
  row?: any;
  hideShowForm: (actionName: string) => void;
  getData: (page: number, pageSize: number, searchKey: string) => void;
  action?: string;
};
export const CategoriesForm: React.FC<Props> = ({
  row,
  hideShowForm,
  getData,
  action,
}) => {
  const [message, setMessage] = useState("");
  const [imageUrl, setImageUrl] = useState(row?.image_url || ""); // State for image URL
  const [isLoading, setIsLoading] = useState(false);

  const formikImage = useFormik({
    initialValues: { image: null },
    onSubmit: async (values) => {
      if (values.image) {
        var formData = new FormData();

        // Check if image is truthy before appending
        formData.append("image", values.image); // Use values.image directly without [0]

        try {
          const res = await uploadImageService(formData);
          if (res && res.data) {
            setImageUrl(res.data.data); // Set imageUrl here
            setMessage("Image Successfully Uploaded : " + res.data.data);
            formik.setFieldValue("image_url", res.data.data);
            console.log(res.data.data);

            formikImage.resetForm();
          } else {
            setMessage("Image not sent");
          }
        } catch (error) {
          setMessage("Failed to upload image");
          console.log("Upload error:", error);
        }
      }
    },
    validationSchema: yup.object({
      image: yup.mixed().required("Select at least 1 image"),
    }),
  });
  const dispatch = useAppDispatch();
  const iValue = {
    category_id: "",
    category_name: "",
    category_url: "",
    category_description: "",
    image_url: "",
  };
  const initialValue =
    action === "edit" ? { ...row, image_url: imageUrl } : iValue;
  const formik = useFormik({
    initialValues: {
      category_id: initialValue.category_id,
      category_name: initialValue.category_name,
      category_url: initialValue.category_url,
      category_description: initialValue.category_description,
      image_url: imageUrl,
    },
    onSubmit: async (values) => {
      if (action === "edit") {
        setIsLoading(true);

        formik.setFieldValue("image_url", imageUrl);
        const response = await updateCategories(values.category_id, values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(setCategoriesMessage("Updated Successfully"));
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(setCategoriesMessage("Some error occured!"));
        }
      } else if (action === "add") {
        setIsLoading(true);

        const response = await addCategories(values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(setCategoriesMessage("Added Successfully"));
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(setCategoriesMessage("Some error occured!"));
        }
      }
      setIsLoading(false);
    },
    validationSchema: yup.object({
      category_name: yup.string().required("Category Name is Required"),
      category_url: yup.string().required("Category URL is Required"),
      image_url: yup.string().required("Image Url is Required"),

      // image: yup.string().required("Image is Required"),
      category_description: yup
        .string()
        .required("Category Description is Required"),
    }),
  });

  const handleUpload = () => {
    // setIsLoading(true);
    console.log("load");
    setTimeout(() => {
      setIsLoading(false); // Reset isLoading after timeout
      console.log("loading stopped");
    }, 5000);

    if (formikImage.values.image) {
      setIsLoading(true);
      console.log("load");
      formikImage.handleSubmit(); // Trigger image upload
    } else {
      setMessage("Please select an image before uploading.");
      setIsLoading(false);
    }
  };
  // const handleUpload = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
  //   formikImage.handleSubmit(e as unknown as React.FormEvent<HTMLFormElement>);
  // };
  return (
    <Card className="shadow mb-4">
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary text-capitalize">
          {action} Categories
          <Button
            className="btn-icon-split float-right"
            onClick={() => hideShowForm("")}
          >
            <span className="icon text-white-50">
              <i className="fas fa-list"></i>
            </span>
            <span className="text">View Categories</span>
          </Button>
        </h6>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group>
            <label className="form -control-label">Category Name</label>
            <Form.Control
              type="text"
              name="category_name"
              className="form-control"
              value={formik.values.category_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.category_name && !!formik.errors.category_name
              }
              isValid={
                !!formik.touched.category_name && !formik.errors.category_name
              }
            ></Form.Control>
            {formik.errors.category_name && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.category_name}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Category URL</label>
            <Form.Control
              type="text"
              name="category_url"
              className="form-control"
              value={formik.values.category_url}
              onChange={(event) => {
                const { name, value } = event.target;
                formik.handleChange(event);
                formik.setFieldValue(name, value.toLowerCase());
              }}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.category_url && !!formik.errors.category_url
              }
              isValid={
                !!formik.touched.category_url && !formik.errors.category_url
              }
            ></Form.Control>
            {formik.errors.category_url && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.category_url}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Category Description</label>
            <Form.Control
              type="text"
              name="category_description"
              className="form-control"
              value={formik.values.category_description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.category_description &&
                !!formik.errors.category_description
              }
              isValid={
                !!formik.touched.category_description &&
                !formik.errors.category_description
              }
            ></Form.Control>
            {formik.errors.category_description && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.category_description}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Upload Image (png, jpg, or jpeg files)</Form.Label>
            <div className="d-flex">
              <Form.Control
                type="file"
                name="image"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const imgFile = event.currentTarget.files?.[0]; // Access files array properly
                  formikImage.setFieldValue("image", imgFile); // Set the file directly
                }}
                onBlur={formikImage.handleBlur}
                isInvalid={
                  !!formikImage.errors.image && !!formikImage.touched.image
                }
                isValid={
                  !formikImage.errors.image && !!formikImage.touched.image
                }
              />
              {formikImage.errors.image && (
                <Form.Control.Feedback type="invalid">
                  {formikImage.errors.image}
                </Form.Control.Feedback>
              )}
              <Button variant="primary" className="ml-2" onClick={handleUpload}>
                Upload
              </Button>
              {isLoading && <Loading />}
            </div>
          </Form.Group>
          {message ? (
            <Col md={12}>
              <Alert variant="info">{message}</Alert>
            </Col>
          ) : null}
          <Form.Group>
            <label className="form -control-label">Image Url</label>
            <Form.Control
              type="text"
              name="image_url"
              className="form-control"
              value={formik.values.image_url}
              onChange={formik.handleChange}
              readOnly={action === "edit"}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.image_url && !!formik.errors.image_url
              }
              isValid={!!formik.touched.image_url && !formik.errors.image_url}
            ></Form.Control>
            {formik.errors.image_url && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.image_url}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group>
            <Button
              type="submit"
              className="float-right mt-2"
              variant="primary"
            >
              Save
            </Button>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
};
