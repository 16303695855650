export const Constant = {
  defaultPageSize: 20,
  defaultPageNumber: 1,
  defaultDropdownPageSize: 100,
  paginationRowsPerPageOptions: [
    10, 20, 40, 60, 80, 100, 120, 140, 160, 180, 200, 220, 240, 260, 280, 300,
    500, 1000, 2000, 5000,
  ],
  defaultAlertVarient: "warning",
  defaultAlertShowTime: 7000,
};
