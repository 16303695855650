import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAdsense } from "../../../interfaces/adsense";

interface IAdsenseData {
  list?: Array<IAdsense>;
  pageNo: number;
  pageSize: number;
  searchKey?: string;
  totalCount?: number;
  message?: string;
}

const initialState: IAdsenseData = {
  pageNo: 1,
  pageSize: 20,
  searchKey: "",
  list: [],
  totalCount: 0,
  message: "",
};

const adsenseSlice = createSlice({
  name: "adsense",
  initialState,
  reducers: {
    setAdsenseList: (state, _action: PayloadAction<IAdsenseData>) => {
      state.list = _action.payload.list;
      state.pageNo = _action.payload.pageNo;
      state.pageSize = _action.payload.pageSize;
      state.totalCount = _action.payload.totalCount;
    },
    resetAdsenseToInit: (state) => {
      state = initialState;
    },
    setAdsenseMessage: (state, _action: PayloadAction<string>) => {
      state.message = _action.payload;
    },
  },
});

export const { setAdsenseList, resetAdsenseToInit, setAdsenseMessage } =
  adsenseSlice.actions;

export default adsenseSlice.reducer;
