import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Button, Card, Col, Form, InputGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { useAppDispatch } from "../../redux/store";
import { resetStatusToInit, setStatusMessage } from "../../redux/actions";
import { Constant } from "../../template/Constant";
import ConfirmationModal from "../../template/ConfirmationModal";
import { deleteStatus } from "../../services/statusService";
import { format } from "date-fns";
import Loading from "../../pages/loading/loading";

type Props = {
  hideShowForm: (action: string) => void;
  handleRowEdit: (row: any) => void;
  getData: (page: number, pageSize: number, searchKey: string) => void;
};
export const StatusTable: React.FC<Props> = ({
  hideShowForm,
  handleRowEdit,
  getData,
}) => {
  const dispatch = useAppDispatch();
  const [rotate, setRotate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [search, setSearch] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const [rowData, setRowData] = useState<any>(undefined);
  const rData = useSelector((state: RootState) => state.status);
  useEffect(() => {
    if (rData && rData.list && rData.list.length === 0) {
      dispatch(resetStatusToInit());
      getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
    }
  }, [rData.list?.length]);
  const handleSearch = () => {
    if (search.length > 0) {
      getData(Constant.defaultPageNumber, Constant.defaultPageSize, search);
    }
  };
  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    await getData(page, newPerPage, "");
  };
  const handlePageChange = (page: number) => {
    getData(page, rData.pageSize, "");
  };
  const handleRowDeleteClick = (row: any) => {
    setShowDelete(true);
    setRowData(row);
  };
  const handleRotate = () => {
    setRotate(true);
    setTimeout(() => {
      setRotate(false);
    }, 2000);
  };
  const handleReset = () => {
    handleRotate();
    setSearch("");
    dispatch(resetStatusToInit());
    getData(Constant.defaultPageNumber, rData.pageSize, "");
  };
  const handleServerDelete = async () => {
    if (rowData) {
      setIsLoading(true);
      try {
        const response = await deleteStatus(rowData.status_id);
        if (response) {
          console.log("Deleted Successfully");
          dispatch(resetStatusToInit());

          dispatch(setStatusMessage("Deleted Successfully"));
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          setShowDelete(false);
        } else {
          dispatch(setStatusMessage("Some error occurred!"));
        }
      } catch (error) {
        console.error("Error deleting Status:", error);
        dispatch(setStatusMessage("Error deleting Status"));
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleRowSelection = (row: any) => {
    console.log(row);
  };
  const handleAddButtonClick = () => {
    dispatch(setStatusMessage(""));
    hideShowForm("add");
  };

  const columns = [
    {
      name: "Article Title",
      selector: (row: any) => row.articles.article_title,
      sortable: true,
      style: { maxWidth: "200px" },
    },
    {
      name: "Subtitle",
      selector: (row: any) => row.sub_title,
      sortable: true,
      style: { maxWidth: "200px" },
    },
    {
      name: "Content",
      selector: (row: any) => row.content,
      sortable: true,
      style: { maxWidth: "200px" },
    },
    {
      name: "External Link",
      selector: (row: any) => row.external_link,
      sortable: true,
      style: { maxWidth: "200px" },
    },
    {
      name: "External Link Title",
      selector: (row: any) => row.external_link_title,
      sortable: true,
      style: { maxWidth: "200px" },
    },
    {
      name: "Image URL",
      selector: (row: any) => row.image_url,
      sortable: true,
      style: { maxWidth: "200px" },
    },
    {
      name: "Creation Date",
      selector: (row: any) => format(new Date(row.creation_date), "MM/dd/yyyy"),
      sortable: true,
      style: { maxWidth: "200px" },
    },
    {
      name: "Actions",
      cell: (row: any) => (
        <div className="d-flex justify-content-between align-items-center">
          <Button
            variant="link"
            className="btn-sm bg-primary text-white"
            onClick={() => handleRowEdit(row)}
          >
            Edit
          </Button>

          <Button
            variant="link"
            className="btn-sm bg-danger text-white mx-2"
            onClick={() => handleRowDeleteClick(row)}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  // const sortedData = rData.list
  //   ? [...rData.list].sort(
  //       (a, b) =>
  //         new Date(b.creation_date || "").getTime() -
  //         new Date(a.creation_date || "").getTime()
  //     )
  //   : [];

  return (
    <Card className="shadow mb-4">
      {isLoading && <Loading />}

      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary">
          Status List ({rData.totalCount})
          <Button
            variant="light"
            className={`btn-circle btn-sm ml-2 ${rotate && "rotate"}`}
            onClick={handleReset}
          >
            <i className="fa fa-refresh"></i>
          </Button>
          <Button
            className="btn-icon-split float-right"
            onClick={handleAddButtonClick}
          >
            <span className="icon text-white-50">
              <i className="fas fa-add"></i>
            </span>
            <span className="text">Add New</span>
          </Button>
        </h6>
      </Card.Header>
      <Card.Body>
        <Col className="ml-auto" md={3} xs={12} xl={3}>
          <InputGroup>
            <Form.Control
              placeholder="Search"
              aria-label="Search"
              aria-describedby="basic-search"
              onChange={(e) => setSearch(e.target.value)}
            />
            <Button
              disabled={search.length <= 2}
              variant="dark"
              className="rounded-0 rounded-right"
              id="button-search"
              onClick={handleSearch}
            >
              Search
            </Button>
          </InputGroup>
        </Col>
        <div className="table-responsive">
          <DataTable
            selectableRows={true}
            onSelectedRowsChange={handleRowSelection}
            paginationPerPage={Constant.defaultPageNumber}
            paginationRowsPerPageOptions={Constant.paginationRowsPerPageOptions}
            columns={columns}
            data={rData.list ? rData.list : []}
            // data={sortedData}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationTotalRows={rData.totalCount}
            className="table table-bordered"
            pagination
            paginationServer
            onChangePage={handlePageChange}
          ></DataTable>
        </div>
      </Card.Body>
      {!isLoading && (
        <ConfirmationModal
          buttonNegative="Cancel"
          buttonPositive="Delete"
          title="Delete Confirmation"
          show={showDelete}
          body={"Are you sure?"}
          onNegative={() => setShowDelete(false)}
          onPositive={handleServerDelete}
        />
      )}
    </Card>
  );
};
