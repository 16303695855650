import { combineReducers } from "redux";

import template from "./slices/template";
import authToken from "./slices/auth";

import adsense from "./slices/adsense";
import articles from "./slices/articles";
import categories from "./slices/categories";
import comments from "./slices/comments";
import likes from "./slices/likes";
import settings from "./slices/settings";
import status from "./slices/status";
import users from "./slices/users";

const rootReducer = combineReducers({
  template,
  authToken,
  adsense,
  articles,
  categories,
  comments,
  likes,
  settings,
  status,
  users,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
