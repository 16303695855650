import { useFormik } from "formik";
import { Button, Card, Form } from "react-bootstrap";
import React, { useState } from "react";

import { useAppDispatch } from "../../redux/store";
import { setSettingsMessage } from "../../redux/actions";
import { addSettings, updateSettings } from "../../services/settingsService";
import { Constant } from "../../template/Constant";
import * as yup from "yup";
import Loading from "../../pages/loading/loading";

type Props = {
  row?: any;
  hideShowForm: (actionName: string) => void;
  getData: (page: number, pageSize: number, searchKey: string) => void;
  action?: string;
};
export const SettingsForm: React.FC<Props> = ({
  row,
  hideShowForm,
  getData,
  action,
}) => {
  const dispatch = useAppDispatch();
  const iValue = {
    setting_id: "",
    blog_name: "",
    favicon_url: "",
    logo_url: "",
    website_color: "",
    secondary_color: "",
    timezone: "",
    seo_description: "",
    allow_comments: "",
    reading_duration: "",
    status_duration: "",
    og_title: "",
    og_description: "",
    privacy_policy: "",
    terms_conditions: "",
    about_us: "",
    disclaimer: "",
    seo_title: "",
  };
  const initialValue = action === "edit" ? row : iValue;
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: async (values) => {
      if (action === "edit") {
        setIsLoading(true);

        const response = await updateSettings(values.setting_id, values);
        if (response && (response.status === 200 || response.status === 201)) {
          console.log(values);
          dispatch(setSettingsMessage("Updated Successfully"));
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(setSettingsMessage("Some error occured!"));
        }
      } else if (action === "add") {
        setIsLoading(true);

        const response = await addSettings(values);

        if (response && (response.status === 200 || response.status === 201)) {
          console.log(values);

          dispatch(setSettingsMessage("Added Successfully"));
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(setSettingsMessage("Some error occured!"));
        }
      }
      setIsLoading(false);
    },
    validationSchema: yup.object({
      blog_name: yup.string().required("Blog Name is Required"),
      favicon_url: yup.string().required("Favicon URL is Required"),
      logo_url: yup.string().required("Logo URL is Required"),
      website_color: yup.string().required("Website Color is Required"),
      timezone: yup.string().required("Timezone is Required"),
      allow_comments: yup.boolean().required("Allow Comments is Required"),
      reading_duration: yup.string().required("Reading Duration is Required"),
      status_duration: yup.string().required("Status Duration is Required"),
      seo_title: yup.string().required("SEO Title is Required"),
      seo_description: yup.string().required("SEO Description is Required"),
      og_title: yup.string().required("OG Title is Required"),
      og_description: yup.string().required("OG Description is Required"),
      email: yup.string().required("Email is Required"),
      password: yup.string().required("Password is Required"),
      // about_us: yup.string().required("About Us is Required"),
      // disclaimer: yup.string().required("Disclaimer is Required"),
      // google_analytics: yup.string().required("Google Analytics is Required"),
      // privacy_policy: yup.string().required("Privacy Policy is Required"),
      // terms_conditions: yup.string().required("Terms and Condition is Required"),
      custom_javascript_code: yup
        .string()
        .required("Custom Javascript Code is Required"),
    }),
  });
  return (
    <Card className="shadow mb-4">
      {isLoading && <Loading />}

      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary text-capitalize">
          {action} Settings
          <Button
            className="btn-icon-split float-right"
            onClick={() => hideShowForm("")}
          >
            <span className="icon text-white-50">
              <i className="fas fa-list"></i>
            </span>
            <span className="text">View Settings</span>
          </Button>
        </h6>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group>
            <label className="form -control-label">Blog Name</label>
            <Form.Control
              type="text"
              name="blog_name"
              className="form-control"
              value={formik.values.blog_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.blog_name && !!formik.errors.blog_name
              }
              isValid={!!formik.touched.blog_name && !formik.errors.blog_name}
            ></Form.Control>
            {formik.errors.blog_name && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.blog_name}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Favicon URL</label>
            <Form.Control
              type="text"
              name="favicon_url"
              className="form-control"
              value={formik.values.favicon_url}
              onChange={(event) => {
                const { name, value } = event.target;
                formik.handleChange(event);
                formik.setFieldValue(name, value.toLowerCase());
              }}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.favicon_url && !!formik.errors.favicon_url
              }
              isValid={
                !!formik.touched.favicon_url && !formik.errors.favicon_url
              }
            ></Form.Control>
            {formik.errors.favicon_url && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.favicon_url}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Logo URL</label>
            <Form.Control
              type="text"
              name="logo_url"
              className="form-control"
              value={formik.values.logo_url}
              onChange={(event) => {
                const { name, value } = event.target;
                formik.handleChange(event);
                formik.setFieldValue(name, value.toLowerCase());
              }}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.touched.logo_url && !!formik.errors.logo_url}
              isValid={!!formik.touched.logo_url && !formik.errors.logo_url}
            ></Form.Control>
            {formik.errors.logo_url && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.logo_url}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Website Color</label>
            <Form.Control
              type="text"
              name="website_color"
              className="form-control"
              value={formik.values.website_color}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.website_color && !!formik.errors.website_color
              }
              isValid={
                !!formik.touched.website_color && !formik.errors.website_color
              }
            ></Form.Control>
            {formik.errors.website_color && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.website_color}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Secondary Color</label>
            <Form.Control
              type="text"
              name="secondary_color"
              className="form-control"
              value={formik.values.secondary_color}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.secondary_color &&
                !!formik.errors.secondary_color
              }
              isValid={
                !!formik.touched.secondary_color &&
                !formik.errors.secondary_color
              }
            ></Form.Control>
            {formik.errors.secondary_color && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.secondary_color}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Timezone</label>
            <Form.Control
              type="text"
              name="timezone"
              className="form-control"
              value={formik.values.timezone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.touched.timezone && !!formik.errors.timezone}
              isValid={!!formik.touched.timezone && !formik.errors.timezone}
            ></Form.Control>
            {formik.errors.timezone && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.timezone}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form-control-label">Allow Comments</label>
            <Form.Control
              as="select"
              name="allow_comments"
              className="form-control"
              value={formik.values.allow_comments}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.allow_comments &&
                !!formik.errors.allow_comments
              }
              isValid={
                !!formik.touched.allow_comments && !formik.errors.allow_comments
              }
            >
              <option value="">Select...</option>
              <option value="1">Yes</option>
              <option value="0">No</option>
            </Form.Control>
            {formik.errors.allow_comments && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.allow_comments}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Reading Duration</label>
            <Form.Control
              type="text"
              name="reading_duration"
              className="form-control"
              value={formik.values.reading_duration}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.reading_duration &&
                !!formik.errors.reading_duration
              }
              isValid={
                !!formik.touched.reading_duration &&
                !formik.errors.reading_duration
              }
            ></Form.Control>
            {formik.errors.reading_duration && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.reading_duration}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Status Duration</label>
            <Form.Control
              type="text"
              name="status_duration"
              className="form-control"
              value={formik.values.status_duration}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.status_duration &&
                !!formik.errors.status_duration
              }
              isValid={
                !!formik.touched.status_duration &&
                !formik.errors.status_duration
              }
            ></Form.Control>
            {formik.errors.status_duration && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.status_duration}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">SEO Title</label>
            <Form.Control
              type="text"
              name="seo_title"
              className="form-control"
              value={formik.values.seo_title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.seo_title && !!formik.errors.seo_title
              }
              isValid={!!formik.touched.seo_title && !formik.errors.seo_title}
            ></Form.Control>
            {formik.errors.seo_title && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.seo_title}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">SEO Description</label>
            <Form.Control
              as="textarea"
              rows={10}
              type="text"
              name="seo_description"
              className="form-control"
              value={formik.values.seo_description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.seo_description &&
                !!formik.errors.seo_description
              }
              isValid={
                !!formik.touched.seo_description &&
                !formik.errors.seo_description
              }
            ></Form.Control>
            {formik.errors.seo_description && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.seo_description}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">OG Title</label>
            <Form.Control
              type="text"
              name="og_title"
              className="form-control"
              value={formik.values.og_title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.touched.og_title && !!formik.errors.og_title}
              isValid={!!formik.touched.og_title && !formik.errors.og_title}
            ></Form.Control>
            {formik.errors.og_title && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.og_title}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">OG Description</label>
            <Form.Control
              as="textarea"
              rows={10}
              type="text"
              name="og_description"
              className="form-control"
              value={formik.values.og_description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.og_description &&
                !!formik.errors.og_description
              }
              isValid={
                !!formik.touched.og_description && !formik.errors.og_description
              }
            ></Form.Control>
            {formik.errors.og_description && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.og_description}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Google Analytics</label>
            <Form.Control
              as="textarea"
              rows={10}
              type="text"
              name="google_analytics"
              className="form-control"
              value={formik.values.google_analytics}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.google_analytics &&
                !!formik.errors.google_analytics
              }
              isValid={
                !!formik.touched.google_analytics &&
                !formik.errors.google_analytics
              }
            ></Form.Control>
            {formik.errors.google_analytics && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.google_analytics}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">
              Custom Javascript Code
            </label>
            <Form.Control
              as="textarea"
              rows={10}
              type="text"
              name="custom_javascript_code"
              className="form-control"
              value={formik.values.custom_javascript_code}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.custom_javascript_code &&
                !!formik.errors.custom_javascript_code
              }
              isValid={
                !!formik.touched.custom_javascript_code &&
                !formik.errors.custom_javascript_code
              }
            ></Form.Control>
            {formik.errors.custom_javascript_code && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.custom_javascript_code}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Privacy Policy</label>
            <Form.Control
              as="textarea"
              rows={10}
              type="text"
              name="privacy_policy"
              className="form-control"
              value={formik.values.privacy_policy}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.privacy_policy &&
                !!formik.errors.privacy_policy
              }
              isValid={
                !!formik.touched.privacy_policy && !formik.errors.privacy_policy
              }
            ></Form.Control>
            {formik.errors.privacy_policy && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.privacy_policy}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Terms and Conditions</label>
            <Form.Control
              as="textarea"
              rows={10}
              type="text"
              name="terms_conditions"
              className="form-control"
              value={formik.values.terms_conditions}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.terms_conditions &&
                !!formik.errors.terms_conditions
              }
              isValid={
                !!formik.touched.terms_conditions &&
                !formik.errors.terms_conditions
              }
            ></Form.Control>
            {formik.errors.terms_conditions && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.terms_conditions}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">About US</label>
            <Form.Control
              as="textarea"
              rows={10}
              type="text"
              name="about_us"
              className="form-control"
              value={formik.values.about_us}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.touched.about_us && !!formik.errors.about_us}
              isValid={!!formik.touched.about_us && !formik.errors.about_us}
            ></Form.Control>
            {formik.errors.about_us && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.about_us}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Disclaimer</label>
            <Form.Control
              as="textarea"
              rows={10}
              type="text"
              name="disclaimer"
              className="form-control"
              value={formik.values.disclaimer}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.disclaimer && !!formik.errors.disclaimer
              }
              isValid={!!formik.touched.disclaimer && !formik.errors.disclaimer}
            ></Form.Control>
            {formik.errors.disclaimer && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.disclaimer}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Email</label>
            <Form.Control
              type="text"
              name="email"
              className="form-control"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.touched.email && !!formik.errors.email}
              isValid={!!formik.touched.email && !formik.errors.email}
            ></Form.Control>
            {formik.errors.email && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.email}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Password</label>
            <Form.Control
              type="text"
              name="password"
              className="form-control"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.touched.password && !!formik.errors.password}
              isValid={!!formik.touched.password && !formik.errors.password}
            ></Form.Control>
            {formik.errors.password && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.password}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group>
            <Button
              type="submit"
              className="float-right mt-2"
              variant="primary"
            >
              {isLoading && <Loading />}
              Save
            </Button>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
};
