import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICategories } from "../../../interfaces/categories";

interface ICategoriesData {
  list?: Array<ICategories>;
  pageNo: number;
  pageSize: number;
  searchKey?: string;
  totalCount?: number;
  message?: string;
}

const initialState: ICategoriesData = {
  pageNo: 1,
  pageSize: 20,
  searchKey: "",
  list: [],
  totalCount: 0,
  message: "",
};

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setCategoriesList: (state, _action: PayloadAction<ICategoriesData>) => {
      state.list = _action.payload.list;
      state.pageNo = _action.payload.pageNo;
      state.pageSize = _action.payload.pageSize;
      state.totalCount = _action.payload.totalCount;
    },
    resetCategoriesToInit: (state) => {
      state = initialState;
    },
    setCategoriesMessage: (state, _action: PayloadAction<string>) => {
      state.message = _action.payload;
    },
  },
});

export const {
  setCategoriesList,
  resetCategoriesToInit,
  setCategoriesMessage,
} = categoriesSlice.actions;

export default categoriesSlice.reducer;
