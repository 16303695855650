import { APIService } from "../services";

export const getUsers = async (
  pageNo: number,
  pageSize: number,
  search: string
) => {
  let res;
  if (search.length === 0) {
    res = await getAllUsers(pageNo, pageSize);
  } else {
    try {
      res = await searchUsers(search, pageNo, pageSize);
    } catch (err) {
      return { records: [], total: 0 };
    }
  }
  if (res && res.data && res.data.data) {
    return { records: res.data.data.data, total: res.data.data.total };
  } else {
    return { records: [], total: 0 };
  }
};

export const addUsers = (data: any) => {
  return APIService.api().post(`/users`, data);
};
export const updateUsers = (user_id: any, data: any) => {
  return APIService.api().put(`/users/${user_id}`, data);
};
export const getAllUsers = (pageNo: number, pageSize: number) => {
  return APIService.api().get(`/users?pageNo=${pageNo}&paginator=${pageSize}`);
};
export const getOneUsers = (user_id: any) => {
  return APIService.api().get(`/users/${user_id}`);
};
export const searchUsers = (
  searchKey: string,
  pageNo: number,
  pageSize: number
) => {
  return APIService.api().get(
    `/users/search/${searchKey}?pageNo=${pageNo}&paginator=${pageSize}`
  );
};
export const deleteUsers = (user_id: any) => {
  return APIService.api().delete(`/users/${user_id}`);
};
