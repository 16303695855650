import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILikes } from "../../../interfaces/likes";

interface ILikesData {
  list?: Array<ILikes>;
  pageNo: number;
  pageSize: number;
  searchKey?: string;
  totalCount?: number;
  message?: string;
}

const initialState: ILikesData = {
  pageNo: 1,
  pageSize: 20,
  searchKey: "",
  list: [],
  totalCount: 0,
  message: "",
};

const likesSlice = createSlice({
  name: "likes",
  initialState,
  reducers: {
    setLikesList: (state, _action: PayloadAction<ILikesData>) => {
      state.list = _action.payload.list;
      state.pageNo = _action.payload.pageNo;
      state.pageSize = _action.payload.pageSize;
      state.totalCount = _action.payload.totalCount;
    },
    resetLikesToInit: (state) => {
      state = initialState;
    },
    setLikesMessage: (state, _action: PayloadAction<string>) => {
      state.message = _action.payload;
    },
  },
});

export const { setLikesList, resetLikesToInit, setLikesMessage } =
  likesSlice.actions;

export default likesSlice.reducer;
