import { APIService } from "../services";

export const getComments = async (
  pageNo: number,
  pageSize: number,
  search: string
) => {
  let res;
  if (search.length === 0) {
    res = await getAllComments(pageNo, pageSize);
  } else {
    try {
      res = await searchComments(search, pageNo, pageSize);
    } catch (err) {
      return { records: [], total: 0 };
    }
  }
  if (res && res.data && res.data.data) {
    return { records: res.data.data.data, total: res.data.data.total };
  } else {
    return { records: [], total: 0 };
  }
};

export const addComments = (data: any) => {
  return APIService.api().post(`/comments`, data);
};
export const updateComments = (comment_id: any, data: any) => {
  return APIService.api().put(`/comments/${comment_id}`, data);
};
export const getAllComments = (pageNo: number, pageSize: number) => {
  return APIService.api().get(
    `/comments?pageNo=${pageNo}&paginator=${pageSize}`
  );
};
export const getOneComments = (comment_id: any) => {
  return APIService.api().get(`/comments/${comment_id}`);
};
export const searchComments = (
  searchKey: string,
  pageNo: number,
  pageSize: number
) => {
  return APIService.api().get(
    `/comments/search/${searchKey}?pageNo=${pageNo}&paginator=${pageSize}`
  );
};
export const deleteComments = (comment_id: any) => {
  return APIService.api().delete(`/comments/${comment_id}`);
};
