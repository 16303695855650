import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IComments } from "../../../interfaces/comments";

interface ICommentsData {
  list?: Array<IComments>;
  pageNo: number;
  pageSize: number;
  searchKey?: string;
  totalCount?: number;
  message?: string;
}

const initialState: ICommentsData = {
  pageNo: 1,
  pageSize: 20,
  searchKey: "",
  list: [],
  totalCount: 0,
  message: "",
};

const commentsSlice = createSlice({
  name: "comments",
  initialState,
  reducers: {
    setCommentsList: (state, _action: PayloadAction<ICommentsData>) => {
      state.list = _action.payload.list;
      state.pageNo = _action.payload.pageNo;
      state.pageSize = _action.payload.pageSize;
      state.totalCount = _action.payload.totalCount;
    },
    resetCommentsToInit: (state) => {
      state = initialState;
    },
    setCommentsMessage: (state, _action: PayloadAction<string>) => {
      state.message = _action.payload;
    },
  },
});

export const { setCommentsList, resetCommentsToInit, setCommentsMessage } =
  commentsSlice.actions;

export default commentsSlice.reducer;
