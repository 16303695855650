import { useFormik } from "formik";
import { Button, Card, Form } from "react-bootstrap";
import React, { useState } from "react";

import { useAppDispatch } from "../../redux/store";
import { setAdsenseMessage } from "../../redux/actions";
import { addAdsense, updateAdsense } from "../../services/adsenseService";
import { Constant } from "../../template/Constant";
import * as yup from "yup";
import Loading from "../../pages/loading/loading";
type Props = {
  row?: any;
  hideShowForm: (actionName: string) => void;
  getData: (page: number, pageSize: number, searchKey: string) => void;
  action?: string;
};
export const AdsenseForm: React.FC<Props> = ({
  row,
  hideShowForm,
  getData,
  action,
}) => {
  const dispatch = useAppDispatch();
  const iValue = {
    adsense_id: "",
    adsense_name: "",
    adsense_code: "",
    is_active: "",
    is_mobilepc: "",
    ad_type: "",
  };
  const initialValue = action === "edit" ? row : iValue;
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: async (values) => {
      if (action === "edit") {
        setIsLoading(true);
        const response = await updateAdsense(values.adsense_id, values);
        console.log("Update Response:", values);

        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(setAdsenseMessage("Updated Successfully"));
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(setAdsenseMessage("Some error occured!"));
        }
      } else if (action === "add") {
        setIsLoading(true);

        const response = await addAdsense(values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(setAdsenseMessage("Added Successfully"));
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(setAdsenseMessage("Some error occured!"));
        }
      }
      setIsLoading(false);
    },
    validationSchema: yup.object({
      adsense_name: yup.string().required("Adsense Name is Required"),
      adsense_code: yup.string().required("Adsense Code is Required"),
      is_active: yup.boolean().required("Active is Required"),
      is_mobilepc: yup.boolean().required("Mobile is Required"),
      ad_type: yup.string().required("Ad Type is Required"),
    }),
  });
  return (
    <Card className="shadow mb-4">
      {isLoading && <Loading />}

      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary text-capitalize">
          {action} Adsense
          <Button
            className="btn-icon-split float-right"
            onClick={() => hideShowForm("")}
          >
            <span className="icon text-white-50">
              <i className="fas fa-list"></i>
            </span>
            <span className="text">View Adsense</span>
          </Button>
        </h6>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group>
            <label className="form -control-label">Adsense Name</label>
            <Form.Control
              type="text"
              name="adsense_name"
              className="form-control"
              value={formik.values.adsense_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.adsense_name && !!formik.errors.adsense_name
              }
              isValid={
                !!formik.touched.adsense_name && !formik.errors.adsense_name
              }
            ></Form.Control>
            {formik.errors.adsense_name && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.adsense_name}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Adsense Code</label>
            <Form.Control
              as="textarea"
              rows={10}
              type="text"
              name="adsense_code"
              className="form-control"
              value={formik.values.adsense_code}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.adsense_code && !!formik.errors.adsense_code
              }
              isValid={
                !!formik.touched.adsense_code && !formik.errors.adsense_code
              }
            ></Form.Control>
            {formik.errors.adsense_code && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.adsense_code}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Active</label>
            <Form.Control
              as="select"
              name="is_active"
              className="form-control"
              value={formik.values.is_active}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.is_active && !!formik.errors.is_active
              }
              isValid={!!formik.touched.is_active && !formik.errors.is_active}
            >
              <option value="">Select...</option>
              <option value="1">Yes</option>
              <option value="0">No</option>
            </Form.Control>
            {formik.errors.is_active && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.is_active}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Mobile</label>
            <Form.Control
              as="select"
              name="is_mobilepc"
              className="form-control"
              value={formik.values.is_mobilepc}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.is_mobilepc && !!formik.errors.is_mobilepc
              }
              isValid={
                !!formik.touched.is_mobilepc && !formik.errors.is_mobilepc
              }
            >
              <option value="">Select...</option>
              <option value="1">Yes</option>
              <option value="0">No</option>
            </Form.Control>
            {formik.errors.is_mobilepc && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.is_mobilepc}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group>
            <label className="form -control-label">Ad Type</label>
            <Form.Control
              as="select"
              name="ad_type"
              className="form-control"
              value={formik.values.ad_type}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.touched.ad_type && !!formik.errors.ad_type}
              isValid={!!formik.touched.ad_type && !formik.errors.ad_type}
            >
              <option value="">Select...</option>
              <option value="Adsense">Adsense</option>
              <option value="AdX">AdX</option>
            </Form.Control>
            {formik.errors.ad_type && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.ad_type}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group>
            <Button
              type="submit"
              className="float-right mt-2"
              variant="primary"
            >
              {isLoading && <Loading />}
              Save
            </Button>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
};
