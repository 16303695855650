import { useFormik } from "formik";
import { Button, Card, Form } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";

import { resetStatusToInit, setStatusList } from "../../redux/actions";
import { resetUsersToInit, setUsersList } from "../../redux/actions";

import { getStatus } from "../../services/statusService";
import { getUsers } from "../../services/usersService";

import { useAppDispatch } from "../../redux/store";
import { setCommentsMessage } from "../../redux/actions";
import { addComments, updateComments } from "../../services/commentsService";
import { Constant } from "../../template/Constant";
import * as yup from "yup";
import Loading from "../../pages/loading/loading";

type Props = {
  row?: any;
  hideShowForm: (actionName: string) => void;
  getData: (page: number, pageSize: number, searchKey: string) => void;
  action?: string;
};
export const CommentsForm: React.FC<Props> = ({
  row,
  hideShowForm,
  getData,
  action,
}) => {
  const dispatch = useAppDispatch();
  const iValue = {
    comment_id: "",
    status_id: "",
    user_id: "",
    comment_text: "",
    comment_date: "",
  };
  const initialValue = action === "edit" ? row : iValue;
  const statusData = useSelector((state: RootState) => state.status);
  const usersData = useSelector((state: RootState) => state.users);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (statusData && statusData.list && statusData.list.length === 0) {
      dispatch(resetStatusToInit());
      getStatus(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setStatusList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total,
              searchKey: "",
            })
          );
        } else {
          dispatch(setCommentsMessage("No Record Found For Status"));
        }
      });
    }
    if (usersData && usersData.list && usersData.list.length === 0) {
      dispatch(resetUsersToInit());
      getUsers(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setUsersList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total,
              searchKey: "",
            })
          );
        } else {
          dispatch(setCommentsMessage("No Record Found For Users"));
        }
      });
    }
  });
  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: async (values) => {
      if (action === "edit") {
        setIsLoading(true);

        const response = await updateComments(values.comment_id, values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(setCommentsMessage("Updated Successfully"));
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(setCommentsMessage("Some error occured!"));
        }
      } else if (action === "add") {
        setIsLoading(true);

        const response = await addComments(values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(setCommentsMessage("Added Successfully"));
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(setCommentsMessage("Some error occured!"));
        }
      }
      setIsLoading(false);
    },
    validationSchema: yup.object({
      status_id: yup.number().required("Status ID is Required"),
      user_id: yup.number().required("User ID is required"),
      comment_text: yup.string().required("Comment Text is Required"),
    }),
  });
  return (
    <Card className="shadow mb-4">
      {isLoading && <Loading />}
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary text-capitalize">
          {action} Comments
          <Button
            className="btn-icon-split float-right"
            onClick={() => hideShowForm("")}
          >
            <span className="icon text-white-50">
              <i className="fas fa-list"></i>
            </span>
            <span className="text">View Comments</span>
          </Button>
        </h6>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group>
            <label className="form -control-label">Status</label>
            <Form.Control
              as="select"
              name="status_id"
              className="form-control"
              value={formik.values.status_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.status_id && !!formik.errors.status_id
              }
              isValid={!!formik.touched.status_id && !formik.errors.status_id}
            >
              <option value={0}>Select Status </option>
              {statusData &&
                statusData.list &&
                statusData.list.map((item, i) => {
                  return (
                    <option value={item.status_id} key={`status-${i}`}>
                      {item.sub_title}
                    </option>
                  );
                })}
            </Form.Control>
            {formik.errors.status_id && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.status_id}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">User</label>
            <Form.Control
              as="select"
              name="user_id"
              className="form-control"
              value={formik.values.user_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.touched.user_id && !!formik.errors.user_id}
              isValid={!!formik.touched.user_id && !formik.errors.user_id}
            >
              <option value={0}>Select Users </option>
              {usersData &&
                usersData.list &&
                usersData.list.map((item, i) => {
                  return (
                    <option value={item.user_id} key={`users-${i}`}>
                      {item.username}
                    </option>
                  );
                })}
            </Form.Control>
            {formik.errors.user_id && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.user_id}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Comment Text</label>
            <Form.Control
              as="textarea"
              rows={10}
              type="text"
              name="comment_text"
              className="form-control"
              value={formik.values.comment_text}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.comment_text && !!formik.errors.comment_text
              }
              isValid={
                !!formik.touched.comment_text && !formik.errors.comment_text
              }
            ></Form.Control>
            {formik.errors.comment_text && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.comment_text}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group>
            <Button
              type="submit"
              className="float-right mt-2"
              variant="primary"
            >
              {isLoading && <Loading />}
              Save
            </Button>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
};
