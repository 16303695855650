export const MenuItems = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: "fa-solid fa-tachometer-alt",
    subMenu: [],
  },
  // {
  //   title: "File Upload",
  //   path: "/upload",
  //   icon: "fa-solid fa-upload",
  //   subMenu: [],
  // },
  {
    title: "Adsense",
    path: "/adsense",
    icon: "fa-solid fa-rectangle-ad",
    subMenu: [],
  },
  {
    title: "Articles",
    path: "/articles",
    icon: "fa-solid fa-newspaper",
    subMenu: [],
  },
  {
    title: "Categories",
    path: "/categories",
    icon: "fa-solid fa-layer-group",
    subMenu: [],
  },
  {
    title: "Comments",
    path: "/comments",
    icon: "fa-solid fa-comment",
    subMenu: [],
  },
  { title: "Likes", path: "/likes", icon: "fa-solid fa-heart", subMenu: [] },
  {
    title: "Settings",
    path: "/settings",
    icon: "fa-solid fa-gear",
    subMenu: [],
  },
  {
    title: "Status",
    path: "/status",
    icon: "fa-solid fa-globe",
    subMenu: [],
  },
  { title: "Users", path: "/users", icon: "fa-solid fa-users", subMenu: [] },
];
