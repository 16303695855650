import { useFormik } from "formik";
import { Alert, Button, Card, Col, Form } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";

import { resetArticlesToInit, setArticlesList } from "../../redux/actions";

import { getArticles } from "../../services/articlesService";

import { useAppDispatch } from "../../redux/store";
import { setStatusMessage } from "../../redux/actions";
import { addStatus, updateStatus } from "../../services/statusService";
import { Constant } from "../../template/Constant";
import * as yup from "yup";
import { uploadImageService } from "../../services/fileUploadService";
import Loading from "../../pages/loading/loading";

type Props = {
  row?: any;
  hideShowForm: (actionName: string) => void;
  getData: (page: number, pageSize: number, searchKey: string) => void;
  action?: string;
};
export const StatusForm: React.FC<Props> = ({
  row,
  hideShowForm,
  getData,
  action,
}) => {
  const [message, setMessage] = useState("");
  const [imageUrl, setImageUrl] = useState(row?.image_url || "");
  const [isLoading, setIsLoading] = useState(false);

  const formikImage = useFormik({
    initialValues: { image: null },
    onSubmit: async (values) => {
      if (values.image) {
        var formData = new FormData();

        // Check if image is truthy before appending
        formData.append("image", values.image); // Use values.image directly without [0]

        try {
          const res = await uploadImageService(formData);
          if (res && res.data) {
            setImageUrl(res.data.data);
            setMessage("Image Successfully Uploaded : " + res.data.data);
            formik.setFieldValue("image_url", res.data.data);
            formikImage.resetForm();
          } else {
            setMessage("Image not sent");
          }
        } catch (error) {
          setMessage("Failed to upload image");
          console.log("Upload error:", error);
        }
      }
    },
    validationSchema: yup.object({
      image: yup.mixed().required("Select at least 1 image"),
    }),
  });

  const dispatch = useAppDispatch();
  const iValue = {
    status_id: "",
    article_id: "",
    sub_title: "",
    external_link: "",
    external_link_title: "",
    content: "",
    image_url: "",
    creation_date: "",
  };
  // const initialValue = action === "edit" ? row : iValue;
  const initialValue =
    action === "edit" ? { ...row, image_url: imageUrl } : iValue;
  const articlesData = useSelector((state: RootState) => state.articles);

  useEffect(() => {
    if (articlesData && articlesData.list && articlesData.list.length === 0) {
      dispatch(resetArticlesToInit());
      getArticles(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setArticlesList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total,
              searchKey: "",
            })
          );
        } else {
          dispatch(setStatusMessage("No Record Found For Articles"));
        }
      });
    }
  });
  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: async (values) => {
      if (action === "edit") {
        setIsLoading(true);

        const response = await updateStatus(values.status_id, values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(setStatusMessage("Updated Successfully"));
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(setStatusMessage("Some error occured!"));
        }
      } else if (action === "add") {
        setIsLoading(true);

        const response = await addStatus(values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(setStatusMessage("Added Successfully"));
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(setStatusMessage("Some error occured!"));
        }
      }
      setIsLoading(false);
      console.log("submitted");
    },
    validationSchema: yup.object({
      // article_id: yup.number().required("Article ID is Required"),
      sub_title: yup.string().required("Subtitle is Required"),
      content: yup.string().required("Content is Required"),
      // image_url: yup.string().required("Image URL is Required"),
    }),
  });
  const handleUpload = () => {
    // setIsLoading(true);
    console.log("load");
    setTimeout(() => {
      setIsLoading(false); // Reset isLoading after timeout
      console.log("loading stopped");
    }, 5000);

    if (formikImage.values.image) {
      setIsLoading(true);
      console.log("load");
      formikImage.handleSubmit(); // Trigger image upload
    } else {
      setMessage("Please select an image before uploading.");
      setIsLoading(false);
    }
  };

  return (
    <Card className="shadow mb-4">
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary text-capitalize">
          {action} Status
          <Button
            className="btn-icon-split float-right"
            onClick={() => hideShowForm("")}
          >
            <span className="icon text-white-50">
              <i className="fas fa-list"></i>
            </span>
            <span className="text">View Status</span>
          </Button>
        </h6>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group>
            <label className="form -control-label">Article</label>
            <Form.Control
              as="select"
              name="article_id"
              className="form-control"
              value={formik.values.article_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.article_id && !!formik.errors.article_id
              }
              isValid={!!formik.touched.article_id && !formik.errors.article_id}
            >
              <option value={0}>Select Articles </option>
              {articlesData &&
                articlesData.list &&
                articlesData.list.map((item, i) => {
                  return (
                    <option value={item.article_id} key={`articles-${i}`}>
                      {item.article_title}
                    </option>
                  );
                })}
            </Form.Control>
            {formik.errors.article_id && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.article_id}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Subtitle</label>
            <Form.Control
              type="text"
              name="sub_title"
              className="form-control"
              value={formik.values.sub_title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.sub_title && !!formik.errors.sub_title
              }
              isValid={!!formik.touched.sub_title && !formik.errors.sub_title}
            ></Form.Control>
            {formik.errors.sub_title && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.sub_title}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Content</label>
            <Form.Control
              as="textarea"
              rows={10}
              type="text"
              name="content"
              className="form-control"
              value={formik.values.content}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.touched.content && !!formik.errors.content}
              isValid={!!formik.touched.content && !formik.errors.content}
            ></Form.Control>
            {formik.errors.content && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.content}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">External Link</label>
            <Form.Control
              // as="textarea"
              // rows={10}
              type="text"
              name="external_link"
              className="form-control"
              value={formik.values.external_link}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.external_link && !!formik.errors.external_link
              }
              isValid={
                !!formik.touched.external_link && !formik.errors.external_link
              }
            ></Form.Control>
            {formik.errors.external_link && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.external_link}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">External Link Title</label>
            <Form.Control
              // as="textarea"
              // rows={10}
              type="text"
              name="external_link_title"
              className="form-control"
              value={formik.values.external_link_title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.external_link_title &&
                !!formik.errors.external_link_title
              }
              isValid={
                !!formik.touched.external_link_title &&
                !formik.errors.external_link_title
              }
            ></Form.Control>
            {formik.errors.external_link_title && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.external_link_title}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Upload Image(png , jpg or jpeg files)</Form.Label>
            <div className="d-flex">
              <Form.Control
                type="file"
                name="image"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const imgFile = event.currentTarget.files?.[0]; // Access files array properly
                  formikImage.setFieldValue("image", imgFile); // Set the file directly
                }}
                onBlur={formikImage.handleBlur}
                isInvalid={
                  !!formikImage.touched.image && !!formikImage.errors.image
                }
                isValid={
                  !!formikImage.touched.image && !formikImage.errors.image
                }
              />
              {formikImage.errors.image && (
                <Form.Control.Feedback type="invalid">
                  {formikImage.errors.image}
                </Form.Control.Feedback>
              )}
              <Button variant="primary" className="ml-2" onClick={handleUpload}>
                Upload
              </Button>
              {isLoading && <Loading />}
            </div>
          </Form.Group>

          {message ? (
            <Col md={12}>
              <Alert variant="info">{message}</Alert>
            </Col>
          ) : null}
          <Form.Group>
            <label className="form -control-label">Article Icon Url</label>
            <Form.Control
              type="text"
              name="image_url"
              className="form-control"
              value={formik.values.image_url}
              onChange={formik.handleChange}
              readOnly={action === "edit"}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.image_url && !!formik.errors.image_url
              }
              isValid={!!formik.touched.image_url && !formik.errors.image_url}
            ></Form.Control>
            {formik.errors.image_url && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.image_url}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Button type="submit" className="float-right mt-2" variant="primary">
            {isLoading && <Loading />}
            Save
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};
