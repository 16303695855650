import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Button, Card, Col, Form, InputGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { useAppDispatch } from "../../redux/store";
import { resetUsersToInit, setUsersMessage } from "../../redux/actions";
import { Constant } from "../../template/Constant";
import ConfirmationModal from "../../template/ConfirmationModal";
import { deleteUsers } from "../../services/usersService";
import { format } from "date-fns";
import Loading from "../../pages/loading/loading";

type Props = {
  hideShowForm: (action: string) => void;
  handleRowEdit: (row: any) => void;
  getData: (page: number, pageSize: number, searchKey: string) => void;
};
export const UsersTable: React.FC<Props> = ({
  hideShowForm,
  handleRowEdit,
  getData,
}) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [search, setSearch] = useState("");
  const [rotate, setRotate] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [rowData, setRowData] = useState<any>(undefined);
  const rData = useSelector((state: RootState) => state.users);
  useEffect(() => {
    if (rData && rData.list && rData.list.length === 0) {
      dispatch(resetUsersToInit());
      getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
    }
  }, [rData.list?.length]);
  const handleSearch = () => {
    if (search.length > 0) {
      getData(Constant.defaultPageNumber, Constant.defaultPageSize, search);
    }
  };
  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    await getData(page, newPerPage, "");
  };
  const handlePageChange = (page: number) => {
    getData(page, rData.pageSize, "");
  };
  const handleRowDeleteClick = (row: any) => {
    setShowDelete(true);
    setRowData(row);
  };
  const handleRotate = () => {
    setRotate(true);
    setTimeout(() => {
      setRotate(false);
    }, 2000);
  };
  const handleReset = () => {
    handleRotate();
    setSearch("");
    dispatch(resetUsersToInit());
    getData(Constant.defaultPageNumber, rData.pageSize, "");
  };
  const handleServerDelete = async () => {
    if (rowData) {
      setIsLoading(true);
      try {
        const response = await deleteUsers(rowData.user_id);
        if (response) {
          console.log("Deleted Successfully");
          dispatch(resetUsersToInit());

          dispatch(setUsersMessage("Deleted Successfully"));
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          setShowDelete(false);
        } else {
          dispatch(setUsersMessage("Some error occurred!"));
        }
      } catch (error) {
        console.error("Error deleting User:", error);
        dispatch(setUsersMessage("Error deleting User"));
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleRowSelection = (row: any) => {
    console.log(row); // Row Selection Functionality can be written here
  };
  const handleAddButtonClick = () => {
    dispatch(setUsersMessage(""));
    hideShowForm("add");
  };

  const columns = [
    { name: "Username", selector: (row: any) => row.username, sortable: true },
    { name: "Email", selector: (row: any) => row.email, sortable: true },
    {
      name: "Password Hash",
      selector: (row: any) => row.password_hash,
      sortable: true,
    },
    {
      name: "Registration Date",
      selector: (row: any) =>
        format(new Date(row.registration_date), "MM/dd/yyyy"),
      sortable: true,
    },
    { name: "Admin", selector: (row: any) => row.is_admin, sortable: true },
    {
      name: "Actions",
      cell: (row: any) => (
        <div className="d-flex justify-content-between align-items-center">
          <Button
            variant="link"
            className="btn-sm bg-primary text-white"
            onClick={() => handleRowEdit(row)}
          >
            Edit
          </Button>

          <Button
            variant="link"
            className="btn-sm bg-danger text-white mx-3"
            onClick={() => handleRowDeleteClick(row)}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];
  return (
    <Card className="shadow mb-4">
      {isLoading && <Loading />}

      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary">
          Users List ({rData.totalCount})
          <Button
            variant="light"
            className={`btn-circle btn-sm ml-2 ${rotate && "rotate"}`}
            onClick={handleReset}
          >
            <i className="fa fa-refresh"></i>
          </Button>
          <Button
            className="btn-icon-split float-right"
            onClick={handleAddButtonClick}
          >
            <span className="icon text-white-50">
              <i className="fas fa-add"></i>
            </span>
            <span className="text">Add New</span>
          </Button>
        </h6>
      </Card.Header>
      <Card.Body>
        <Col className="ml-auto" md={3} xs={12} xl={3}>
          <InputGroup>
            <Form.Control
              placeholder="Search"
              aria-label="Search"
              aria-describedby="basic-search"
              onChange={(e) => setSearch(e.target.value)}
            />
            <Button
              disabled={search.length <= 2}
              variant="dark"
              className="rounded-0 rounded-right"
              id="button-search"
              onClick={handleSearch}
            >
              Search
            </Button>
          </InputGroup>
        </Col>
        <div className="table-responsive">
          <DataTable
            selectableRows={true}
            onSelectedRowsChange={handleRowSelection}
            paginationPerPage={Constant.defaultPageNumber}
            paginationRowsPerPageOptions={Constant.paginationRowsPerPageOptions}
            columns={columns}
            data={rData.list ? rData.list : []}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationTotalRows={rData.totalCount}
            className="table table-bordered"
            pagination
            paginationServer
            onChangePage={handlePageChange}
          ></DataTable>
        </div>
      </Card.Body>
      {!isLoading && (
        <ConfirmationModal
          buttonNegative="Cancel"
          buttonPositive="Delete"
          title="Delete Confirmation"
          show={showDelete}
          body={"Are you sure?"}
          onNegative={() => setShowDelete(false)}
          onPositive={handleServerDelete}
        />
      )}
    </Card>
  );
};
